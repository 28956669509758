<template>
  <div>
    <v-btn color="primary" class="pa-4 mr-2" small @click.stop="dialog = true">
      <slot></slot>
    </v-btn>

    <v-dialog v-model="dialog" max-width="700">
      <v-card>
        <v-card-title class="text-h5"> {{ $t('EncerrarAtendimento.closingService') }} </v-card-title>
        <v-col cols="12">
          <v-textarea
            v-model="obsAttendence"
            class="mb-n5"
            filled
            :label="$t('EncerrarAtendimento.comment')"
          >
          </v-textarea>
        </v-col>
       <v-col cols="12">
        <v-select
          return-object
          v-model="reason"
          :items="reasons"
          item-text="reason_description"
          item-value="reason_id"
          :label="$t('FilaAtendimientoChat.reason')"
          required
        ></v-select>
       </v-col> 
       <v-col v-if="reason != null">
        <v-select 
          return-object
          v-model="subreason"
          :items="subreasons"
          item-text="sub_reason_description"
          item-value="sub_reason_Id"
          :label="$t('FilaAtendimientoChat.subreason')"
          required
        ></v-select>
       </v-col>
        <v-col cols="12">
          <p>{{ $t('EncerrarAtendimento.status') }}</p>

          <v-btn-toggle borderless color="primary">
            <v-row>
              <v-col>
                <v-btn-toggle class="d-flex flex-wrap" v-model="completionId">
                  <v-btn
                    class="ma-2"
                    v-for="item in codigosFechamentos"
                    :key="item.completion_Id"
                    :value="item.completion_Id"
                  >
                    <span>{{ item.completion_Description }}</span>
                    <!-- <v-icon color="primary" small class="pl-2">
                    mdi-check-bold
                  </v-icon> -->
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-btn-toggle>
        </v-col>
        <v-col cols="12">
          {{ $t('EncerrarAtendimento.campaignName') }}
          <v-select
            v-model="campaign"
            :items="filterCampaigns"
            return-object
            item-text="campaign_Name"
            item-value="campaign_Id"
            :label="$t('EncerrarAtendimento.campaigns')"
          ></v-select>
        </v-col>
        <v-col cols="12">
          <p>{{ $t('EncerrarAtendimento.typeClient') }}</p>
          <v-btn-toggle borderless color="primary">
            <v-btn style="font-size: 30px"> 😀 </v-btn>

            <v-btn style="font-size: 30px"> 😐 </v-btn>

            <v-btn style="font-size: 30px"> ☹️ </v-btn>
          </v-btn-toggle>
        </v-col>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" text @click="dialog = false"> {{ $t('Guid.cancel') }} </v-btn>

          <v-btn
            color="primary"
            text
            @click="[(dialog = false), encerrarAtendimento()]"
          >
          {{ $t('Guid.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
  props: ["attendenceId","clientName"],
  data() {
    return {
      token: JSON.parse(localStorage.getItem("authUser")).token,

      ext_opp : axios.create({
          baseURL: process.env.VUE_APP_ENV_API_ATT_EXT_OPP
      }),

      listaAtendimentosFila : [],
      dialog: false,
      codigosFechamentos: [],
      campaigns : [],
      obsAttendence: "",
      completionId: 0,
      campaign: null,

      encerrandoAtendimento: false,
      reasons: [],
      subreasons: [],
      reason: null,
      subreason: null
    };
  },
  methods: {
    encerrarAtendimento() {
      let reasonId = null;
      let subReasonId = null;

      if(this.reason != null && this.reason.reason_id != null) {
        reasonId = this.reason.reason_id
      }
      if(this.subreason !== null && this.subreason.sub_reason_Id !== null) {
        subReasonId = this.subreason.sub_reason_Id;
      }
      if (this.campaign == null) {
        this.$emit("errorCampaign")
      } else if (this.campaign != null && this.campaign.campaign_Id > 0) {
        this.setCampaign(this.attendenceId, this.completionId, this.obsAttendence.replace(" ", "%20"));
      } else if(this.campaign != null && this.campaign.campaign_Id == -1) {
        if(this.completionId != 0 && this.completionId != null && this.completionId !== undefined) {
          this.$http
            .post(
              `User_Att_Close?Attendence_Id=${this.attendenceId}
              &Completion_Id=${this.completionId}
              &Obs_Attendence=${this.obsAttendence.replace(" ", "%20")}&Customer_Id=${localStorage.getItem("customer_Id")}
              &Reason_Id=${reasonId}
              &Subreason_Id=${subReasonId}`,
              {},
              {
                headers: {
                  Authorization: `bearer ${this.token}`,
                },
              }
            )
            .then(() => {
              this.encerrandoAtendimento = !this.encerrandoAtendimento
              this.$emit("encerrandoAtendimento", [this.encerrandoAtendimento, this.attendenceId]);
            })
            .catch((error) => {
              if(error.response.status == 401) {
                this.$store.commit('setDisconnectedByNetworkError', true)
                this.logout()
              }
              //this.error = true
            });
        } else {
          this.$emit("errorCloseService")
        }
      } else {
        this.$emit("errorCampaign")
      }
    },
    setCampaign(attendence_Id, completion_Id, attendence_obs){
      this.ext_opp
        .get(`Light_Ext_Change_Flow_Attendence?customer_id=${localStorage.getItem("customer_Id")}&attendence_id=${attendence_Id}&completion_id=${completion_Id + 1}&obs_attendence=${attendence_obs}&flow_name=${this.campaign.flow_Name.replace(" ", "%20")}`, {
          headers: {
            accept: "*/*",
          },
        })
        .then(() =>{
          this.campaign = null
          this.encerrandoAtendimento = !this.encerrandoAtendimento;
          this.$emit("encerrandoAtendimento", [this.encerrandoAtendimento, this.attendenceId]);
        })
        .catch((error) => {
          if(error.response.status == 401) {
            this.$store.commit('setDisconnectedByNetworkError', true)
            this.logout()
          }
        })
    },
    campaignReport(){
      this.$store.dispatch('campaignList', localStorage.getItem("customer_Id"));
      this.campaigns = this.getListCampaigns;
    },
    getLocalDate(start_Queue) {
      let newDate = new Date(start_Queue + 'Z');
      let formatDate = 
        [
          newDate.getFullYear(),
          newDate.getMonth()+1,
          newDate.getDate()
        ].join('-') +' ' +
        [newDate.getHours(),
          String(newDate.getMinutes()).padStart(2, '0'),
          String(newDate.getSeconds()).padStart(2, '0')].join(':').toString();
          
      return formatDate;
    },
    getListReason() {
      this.$http
        .get(`Reason_Att?Customer_Id=${localStorage.getItem("customer_Id")}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          this.reasons = r.data;
        })
        .catch((error) => {
          if(error.response.status == 401) {
            this.$store.commit('setDisconnectedByNetworkError', true)
            this.logout()
          }
        })
        .finally(() => {
          //this.loading = false
        });
    },
    getListSubreason(){
      this.$http
        .get(`SubReason_Att?Customer_Id=${localStorage.getItem("customer_Id")}`, {
          headers: {
            accept: "*/*",
            Authorization: `bearer ${this.token}`,
          },
        })
        .then((r) => {
          this.subreasons = r.data;
        })
        .catch((error) => {
          if(error.response.status == 401) {
            this.$store.commit('setDisconnectedByNetworkError', true)
            this.logout()
          }
        })
        .finally(() => {
          //this.loading = false
        });
    }
  },
  computed:{
    ...mapGetters(['getListUserFunctions']),
    ...mapGetters(['getListCampaigns']),
    ...mapGetters(['getListGroups']),
    filterCampaigns() {
      let currentActiveCampaigns = []

      for(var i=0;i< this.getListCampaigns.length; i++){ //Save active campaigns
          if(Date.parse(this.getLocalDate(this.getListCampaigns[i].start_Date)) < Date.now() && 
            Date.now() < Date.parse(this.getLocalDate(this.getListCampaigns[i].end_Date)) && this.getListCampaigns[i].enable_Agent) {
            currentActiveCampaigns.push(this.getListCampaigns[i])
          }
        }

      currentActiveCampaigns = currentActiveCampaigns.sort(function(a,b) {
        if (a.campaign_Name > b.campaign_Name) {
          return 1;
        }
        if (a.campaign_Name < b.campaign_Name) {
          return -1;
        }
        return 0;
      })
        
      if (currentActiveCampaigns.length > 0) {
        return currentActiveCampaigns
      } else {
        currentActiveCampaigns.push({
          "attendence_Group_Id" : null,
          "campaign_Id" : -1, 
          "campaign_Name" : "No configuring...",
          "channel_Id" : null,
          "customer_Id" : null,
          "enable_Agent" : null,
          "end_Date" : null,
          "flow_Id" : null,
          "flow_Name" : null, 
          "interval" : null, 
          "origin_Campaign_Id" : null, 
          "start_Date" : null, 
          "step_Id" : null, 
          "subject" : null, 
          "template" : null})

          return currentActiveCampaigns
      }
    }
  },
  mounted() {
    this.campaignReport()
    this.getListReason()
    this.getListSubreason()

    this.$http
      .get(`List_Att_Completion_Id?Customer_Id=${localStorage.getItem("customer_Id")}`, {
        headers: {
          accept: "*/*",
          Authorization: `bearer ${this.token}`,
        },
      })
      .then((r) => {
        this.codigosFechamentos = r.data;
        this.completionId = this.codigosFechamentos[0].completion_Id
      })
      .catch((error) => {
        if(error.response.status == 401) {
          this.$store.commit('setDisconnectedByNetworkError', true)
          this.logout()
        }
      })
      .finally(() => {
        //this.loading = false
      });
  },
  watch: {
    getListCampaigns() {
      this.campaigns = this.getListCampaigns;
    },
    filterCampaigns() {
      this.campaign = this.filterCampaigns[0];
    }
  }
};
</script>

<style>
</style>