<template>
  <div class="vuec-app">
    <v-app>
      <!-- <router-view name="menu"/> -->
      <router-view/>
    </v-app>
    <footer>
      {{version}} 
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    version: 'v1.240529-00.62s'
    //
  }),
  mounted() {
    let userLang = navigator.language || navigator.userLanguage;
    switch(userLang.substring(0, 2)) {
      case 'en': 
        this.$root.$i18n.locale = 'en';
        break;
      case 'pt':
        this.$root.$i18n.locale = 'pt';
        break;
      case 'es':
        this.$root.$i18n.locale = 'es';
        break;
      default:
        this.$root.$i18n.locale = 'en';
        break;
    }
  },
  created() {
    if (localStorage.getItem('authUser')) {
      this.$store.dispatch('getPermissions');
    }
  }
};
</script>

<style>
</style>
